// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
//require("easy-autocomplete")
import "chartkick/chart.js"
//import "easy-autocomplete"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


jQuery(function() {
  const clubheads = $('#club_clubhead_id').html();
  return $('#club_clubhead_type_id').change(function() {
    const clubhead_type = $('#club_clubhead_type_id :selected').text();
    const options = $(clubheads).filter(`optgroup[label=${clubhead_type}]`).html();
    console.log(options);
    if (options) {
      return $('#club_clubhead_id').html(options);
    } else {
      return $('#club_clubhead_id').empty();
    }
  });
});



import "packs/clubheads"